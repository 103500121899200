import React from 'react'
import { node } from 'prop-types'
import Helmet from 'react-helmet'

import { GlobalStyle } from 'styles/global'
import Nav from 'components/Nav'

const Layout = ({ children, location }) => (
  <>
    <GlobalStyle />
    <Helmet title="François Deguire" />
    <Nav forceFullNav={location.pathname === '/'} />
    {children}
  </>
)

Layout.propTypes = {
  children: node.isRequired,
}

export default Layout
