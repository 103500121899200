import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { media } from './media'
import { primaryColor, black } from './colors'
import { animationTime, animationCurve } from './variables'

// Font family
export const fontFamily =
  "'Object Sans'"

// Font weights
export const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
}

export const lineHeights = {
  base: 1.5,
  heading: 1.25,
  tight: 1.05,
}

// Font sizes
export const fontSizes = {
  regular: '16px',
  small: '14px',
  tiny: '12px',
}

export const Lead = styled.p`
  font-size: 1.1em;

  ${media.medium`
    font-size: 1.2em;
  `};
`

export default css`
  html {
    font-size: ${fontSizes.regular};

    ${media.small`
      font-size: calc(16px + 6 * ((100vw - 320px) / 680));
    `};
  }

  body {
    font-family: ${fontFamily};
    font-weight: ${fontWeights.regular};
    line-height: ${lineHeights.base};
    color: ${black};
  }

  small {
    font-size: ${fontSizes.small};
  }

  strong {
    font-weight: ${fontWeights.bold};
  }

  p {
    margin-bottom: 1.25em;

    &:last-child {
      margin-bottom: 0;
    }

    ${media.medium`
      margin-bottom: 1.5em;
    `};
  }

  p a {
    color: ${primaryColor};
    border-bottom: 0.05em solid ${rgba(primaryColor, 0.2)};
    transition: border-color ${animationTime} ${animationCurve};

    &:hover,
    &:focus {
      border-color: ${rgba(primaryColor, 0.4)};
    }
  }
`
