import { css } from 'styled-components'
import { rgba } from 'polished'

import { black } from './colors'

// Vertical padding
export const verticalPadding = padding => css`
  padding-top: ${padding};
  padding-bottom: ${padding};
`

// Square
export const square = size => css`
  width: ${size};
  height: ${size};
`

export const boxShadow = `box-shadow: 0 2px 6px 0 ${rgba(black, 0.2)};`

// Cover
export function cover(position) {
  return `
    position: ${position};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `
}
