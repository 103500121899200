import React from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'

import * as spacing from 'styles/spacing'
import { media } from 'styles/media'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-left: ${spacing.large};
  padding-right: ${spacing.large};

  ${media.small`
    padding-left: 5%;
    padding-right: 5%;
  `};
`

const Wrapper = ({ children }) => <Container>{children}</Container>

Wrapper.propTypes = {
  children: node.isRequired,
}

export default Wrapper
