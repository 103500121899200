import React from 'react'
import styled, { css } from 'styled-components'
import { bool } from 'prop-types'
import { Link } from 'gatsby'

import { verticalPadding } from 'styles/helpers'
import { black, white } from 'styles/colors'
import { animationTime, animationCurve } from 'styles/variables'
import { fontWeights } from 'styles/typography'
import * as spacing from 'styles/spacing'
import { media } from 'styles/media'

import MobileNav from 'components/MobileNav'
import Wrapper from 'components/Wrapper'

const Mask = styled.span`
  transition: opacity ${animationTime} ${animationCurve};
`

const Container = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${white};
  z-index: 500;
  ${verticalPadding('24px')};

  ${media.small`
    ${verticalPadding('32px')};
  `};

  ${media.large`
    ${verticalPadding('40px')};
  `};

  ${props => !props.forceFullNav && css`
    ${Mask} {
      opacity: 0;
    }
  `}

  &:hover {
    ${Mask} {
      opacity: 1;
    }
  }
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Logo = styled(Link)`
  font-weight: ${fontWeights.bold};
  color: ${black};
`

const List = styled.div`
  display: flex;
`

const Item = styled(Link)`
  color: ${black};
  font-weight: ${fontWeights.bold};

  & + & {
    margin-left: ${spacing.large};

    ${media.small`
      margin-left: ${spacing.xLarge};
    `};

    ${media.medium`
      margin-left: ${spacing.xxLarge};
    `};

    ${media.xLarge`
      margin-left: ${spacing.xxxLarge};
    `};
  }
`

const Nav = ({ forceFullNav }) => (
  <Container forceFullNav={forceFullNav}>
    <Wrapper>
      <Inner>
        <Logo to="/">
          F<Mask>rançois</Mask> D
          <Mask>eguire</Mask>
        </Logo>
        <MobileNav />
        <List className="u-hide-medium-down">
          <Item to="/about">
            A<Mask>bout</Mask>
          </Item>
          <Item to="/contact">
            C<Mask>ontact</Mask>
          </Item>
          <Item to="/lab">
            <Mask>The </Mask>L
            <Mask>ab</Mask>
          </Item>
        </List>
      </Inner>
    </Wrapper>
  </Container>
)

Nav.propTypes = {
  forceFullNav: bool,
}

export default Nav
