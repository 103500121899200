import React from 'react'
import { string, bool } from 'prop-types'
import styled from 'styled-components'
import * as spacing from 'styles/spacing'

const getSpacing = ({ small, medium }) => {
  if (small) return spacing.medium
  if (medium) return spacing.xLarge
  return spacing.large
}

class Icon extends React.Component {
  state = {
    IconComponent: null,
  }

  async componentDidMount() {
    const { default: IconComponent } = await import(
      `assets/icons/${this.props.icon}.svg`
    )
    this.setState({ IconComponent })
  }

  render() {
    const { icon, ...rest } = this.props
    const { IconComponent } = this.state

    if (!IconComponent) return null

    const Container = styled(({ small, medium, ...rest }) => (
      <IconComponent {...rest} />
    ))`
      display: inline-block;
      fill: currentColor;
      width: ${getSpacing};
      height: ${getSpacing};
      vertical-align: top;
    `

    return <Container {...rest} />
  }
}

Icon.propTypes = {
  icon: string.isRequired,
  small: bool,
  medium: bool,
}

export default Icon
