// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lab-js": () => import("./../src/pages/lab.js" /* webpackChunkName: "component---src-pages-lab-js" */),
  "component---src-pages-projects-cab-js": () => import("./../src/pages/projects/cab.js" /* webpackChunkName: "component---src-pages-projects-cab-js" */),
  "component---src-pages-projects-clark-js": () => import("./../src/pages/projects/clark.js" /* webpackChunkName: "component---src-pages-projects-clark-js" */),
  "component---src-pages-projects-expo-js": () => import("./../src/pages/projects/expo.js" /* webpackChunkName: "component---src-pages-projects-expo-js" */),
  "component---src-pages-projects-hyperlocal-js": () => import("./../src/pages/projects/hyperlocal.js" /* webpackChunkName: "component---src-pages-projects-hyperlocal-js" */),
  "component---src-pages-projects-libra-js": () => import("./../src/pages/projects/libra.js" /* webpackChunkName: "component---src-pages-projects-libra-js" */),
  "component---src-pages-projects-pceats-js": () => import("./../src/pages/projects/pceats.js" /* webpackChunkName: "component---src-pages-projects-pceats-js" */),
  "component---src-pages-projects-pebble-js": () => import("./../src/pages/projects/pebble.js" /* webpackChunkName: "component---src-pages-projects-pebble-js" */),
  "component---src-pages-projects-peel-js": () => import("./../src/pages/projects/peel.js" /* webpackChunkName: "component---src-pages-projects-peel-js" */),
  "component---src-pages-projects-phantom-js": () => import("./../src/pages/projects/phantom.js" /* webpackChunkName: "component---src-pages-projects-phantom-js" */),
  "component---src-pages-projects-strix-js": () => import("./../src/pages/projects/strix.js" /* webpackChunkName: "component---src-pages-projects-strix-js" */),
  "component---src-pages-projects-sukiyaki-js": () => import("./../src/pages/projects/sukiyaki.js" /* webpackChunkName: "component---src-pages-projects-sukiyaki-js" */),
  "component---src-pages-projects-tdbank-js": () => import("./../src/pages/projects/tdbank.js" /* webpackChunkName: "component---src-pages-projects-tdbank-js" */),
  "component---src-pages-projects-vynl-js": () => import("./../src/pages/projects/vynl.js" /* webpackChunkName: "component---src-pages-projects-vynl-js" */)
}

