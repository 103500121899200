import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { black, white } from 'styles/colors'

import Portal from 'components/Portal'
import Heading from 'components/Heading'
import Icon from 'components/Icon'

const Container = styled.div`
  position: fixed;
  background-color: ${black};
  top: 0;
  right: 0;
  left: 10%;
  bottom: 0;
  z-index: 600;
  padding: 12.5%;
`

const Trigger = styled.button`
  display: flex;
`

const Close = styled.button`
  display: flex;
  margin-bottom: 12.5%;
  margin-left: auto;
  color: ${white};
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
`

const Item = styled(Link)`
  color: ${white};

  & + & {
    margin-top: 12.5%;
  }
`

const Label = styled(Heading)`
  color: ${white};
`

const MobileNav = () => {
  const [expanded, setExpanded] = useState(false)
  return (
    <div className="u-hide-medium-up">
      <Trigger
        onClick={() => {
          setExpanded(!expanded)
        }}
      >
        <Icon icon="hamburger" medium />
      </Trigger>
      {expanded && (
        <Portal selector={'nav-portal'}>
          <Container>
            <Close
              onClick={() => {
                setExpanded(!expanded)
              }}
            >
              <Icon icon="left-arrow" medium />
            </Close>
            <List>
              <Item
                onClick={() => {
                  setExpanded(!expanded)
                }}
                to="/"
              >
                <Label>Home</Label>
              </Item>
              <Item
                onClick={() => {
                  setExpanded(!expanded)
                }}
                to="/about"
              >
                <Label>About</Label>
              </Item>
              <Item
                onClick={() => {
                  setExpanded(!expanded)
                }}
                to="/contact"
              >
                <Label>Contact</Label>
              </Item>
              <Item
                onClick={() => {
                  setExpanded(!expanded)
                }}
                to="/lab"
              >
                <Label>The Lab</Label>
              </Item>
            </List>
          </Container>
        </Portal>
      )}
    </div>
  )
}

export default MobileNav
