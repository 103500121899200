import React from 'react'

import styled from 'styled-components'

import { fontWeights } from 'styles/typography'
import { black } from 'styles/colors'

const Container = styled.h3`
  font-weight: ${fontWeights.bold};
  color: ${black};
  margin-bottom: 1em;
  font-size: 2rem;
  line-height: 1.15;

  &:last-child {
    margin-bottom: 0;
  }
`

const Heading = ({ ...props }) => <Container {...props} />

export default Heading
